<template>
	<div>
        <a-row type="flex" :gutter="[2, 2]">
            <a-divider orientation="left">
				<span style="color:black; font-size:18px;">Usuarios</span> <span style="color:#9C9C9C; font-size:12px;">Visualiza la lista de usuarios</span>
			</a-divider>

            <a-col flex="1 1 100px">
				<a-button
                    v-if="$can('manager.crear.usuario')" 
                    type="primary" 
                    @click.once="openModalUsuario('agregar', null)" 
                    :key="buttonKeyRegistrar"
                    block
                    >
                    <a-icon type="plus" /> Nuevo Usuario
                </a-button>
            </a-col>
            
            <a-col flex="1 1 100px">
                <a-button 
                    v-if="$can('manager.vaciar.papelera.usuario')"
                    type="dashed" 
                    @click.once="openModalLimpiarPapelera()" 
                    :key="buttonKeyLimpiar"
                    block
                    >
                    <a-icon type="delete" /> Vaciar Papelera
                </a-button>
            </a-col>

            <a-col flex="1 1 100px">
				<a-button 
                    type="dashed" 
                    :key="buttonKeyLimpiar"
                    block
                    >
                    <a-icon type="delete" /> Cerrar Sesiones
                </a-button>
            </a-col>

            <a-col flex="1 1 200px">
				
			</a-col>

            <a-col flex="1 1 400px">
                <a-input-search placeholder="Buscar usuarios" @search="onSearchUsuario">
                    <a-button type="primary" slot="enterButton">
                        Buscar
                    </a-button>
                </a-input-search>
            </a-col>

            <a-col :span="1">
                <a-button @click="obtenerListadoActual">
                    <a-icon type="reload" />
                </a-button>
            </a-col>
        </a-row>

        <br>
        
        <a-table 
            :columns="columns" 
            :data-source="usuarios" 
            :pagination="paginate"
            :loading="loading"
            :ellipsis="true"
            size="small"
            :rowKey="record => record.usuario_id"
            :scroll="{ x: 980 }"
            @change="handlePagination"
            >
            
            <template slot="estado" slot-scope="estado">
                <a-tag color="green" v-if="estado === 'HABILITADO'">
                    HABILITADO
                </a-tag>
                <a-tag color="red" v-if="estado === 'DESHABILITADO'">
                    DESHABILITADO
                </a-tag>
            </template>

            <template slot="roles" slot-scope="item">
                <template v-if="Object.entries(item.roles).length > 0">
                    <span v-for="(value, index) in item.roles" :key="index">
                        <span v-if="value.name === 'administrador'" style="color:#197903; font-size:14px; font-weight:700;">&nbsp;{{ index + 1 }}. {{ value.name.toUpperCase() }} <br></span>
                        <span v-if="value.name != 'administrador'" style="color:#4d2b80; font-size:14px; font-weight:700;">&nbsp;{{ index + 1 }}. {{ value.name.toUpperCase() }}<br></span>
                    </span>
                </template>

                <span v-if="Object.entries(item.roles).length === 0" style="color:#8a0900; font-size:14px; font-weight:700;">Sin Rol Asignado<br></span>
            </template>

            <template slot="Sucursal" slot-scope="item">
                {{ item.almacen }}
            </template>

            <span slot="expandedRowRender" slot-scope="item" style="margin: 0">
                <span style="font-weight:700; font-size:17px;">DATOS DE REGISTRO</span>
                <a-row>
                    <a-col :span="6">
                        <a-list size="small" bordered><a-list-item>DIRECCION:</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>EMAIL:</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>NOMBRE COMPLETO:</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>USUARIO:</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>TELEFONO:</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>FECHA DE CREACION:</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>FECHA DE ACTUALIZACION:</a-list-item></a-list>
                    </a-col>
                    <a-col :span="18">
                        <a-list size="small" bordered><a-list-item>{{ item.direccion }}</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>{{ item.email }}</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>{{ item.nombre }}</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>{{ item.usuario }}</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>{{ item.telefono }}</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>{{ utils.formatDate(item.created_at) }}</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>{{ utils.formatDate(item.created_at) }}</a-list-item></a-list>
                    </a-col>
                </a-row>
            </span>

            <template slot="action" slot-scope="item">
                <a-dropdown>
                    <a-menu slot="overlay">
                        <a-menu-item v-if="$can('manager.editar.usuario')" @click.once="openModalUsuario('editar', item)" :key="buttonKeyActualizar">
                            <a-tooltip placement="topLeft">
                                <template slot="title">
                                    <span style="font-size:11px;">ID: {{ item.usuario_id }}</span>
                                </template>
                                <a-icon type="form" :style="{ fontSize: '18px', color: '#006ccc' }"/> <strong>Editar Usuario</strong>
                            </a-tooltip>
                        </a-menu-item>

                        <a-menu-item v-if="$can('manager.eliminar.usuario')">
                            <a-popconfirm
								title="¿Estás seguro(a) de eliminar este registro?"
								ok-text="Si"
								cancel-text="No"
								placement="topLeft"
								@confirm="eliminar(item.usuario_id)"
								>
								<a-tooltip>
									<template slot="title">
										<span>ID: {{ item.usuario_id }}</span>
									</template>
									<a-icon type="delete" :style="{ fontSize: '18px', color: '#cc2400' }"/> <strong>Eliminar Usuario</strong>
								</a-tooltip>
							</a-popconfirm>
                        </a-menu-item>
                    </a-menu>
                    <a-button> Acciones <a-icon type="down" /> </a-button>
                </a-dropdown>
            </template> 
        </a-table>
        
        <!-- MODAL USUARIO -->
        <a-modal
			v-model="modalUsuario"
			title="GESTION DE USUARIOS"
			:closable="true"
			:destroyOnClose="true"
			:maskClosable="false"
			:dialog-style="{ top: '5px' }"
			:width="380"
			:zIndex="1049"
			>

			<div class="row mb-12">

                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="control-label">Seleccione Sucursal:</label>
                            <a-select size="large" label-in-value :value="{ key: form.almacenID }" @change="onSelectSucursal"  style="width: 100%">
                                <a-select-option v-for="(value, index) in almacenes" :key="index" :value="value.almacen_id"> {{ value.nombre }}</a-select-option>
                            </a-select>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label>Nombre Completo: <span style="font-size:11px; font-weight:700; color:#05729b;"> Obligatorio *</span></label>
                            <a-input size="large" v-model="form.nombre"  placeholder="Nombre Completo" onfocus="myFunction(this)" style="width: 100%" />
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <label>Direccion: <span style="font-size:11px; font-weight:700; color:#05729b;"> Obligatorio *</span></label>
                        <a-textarea v-model="form.direccion" placeholder="Direccion de Usuario" onfocus="myFunction(this)" :rows="4" />
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <label>Teléfono Celular: <span style="font-size:11px; font-weight:700; color:#05729b;"> Obligatorio *</span></label>
                        <a-input size="large" v-model="form.telefono" placeholder="Teléfono de Usuario" onfocus="myFunction(this)"/>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <label>Correo Electrónico: <span style="font-size:11px; font-weight:700; color:#05729b;"> Obligatorio *</span></label>
                        <a-input 
                            size="large" 
                            v-model="form.email" 
                            placeholder="Correo Electrónico" 
                            onfocus="myFunction(this)"
                            @keyup="capturarTecla"
                        />
                    </div>
                </div>

                <div class="row">
					<div class="col-md-12">
						<span class="d-sm-block"><strong>Estado Usuario:</strong></span>
						<a-select size="large" :value="form.estado" @change="onSelectEstado" style="width: 100%">
                            <a-select-option v-for="(value, index) in lista_estado" :key="index" :value="value.nombre"> {{ value.nombre }}</a-select-option>
                        </a-select>
					</div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label>Usuario: <span style="font-size:11px; font-weight:700; color:#05729b;"> Obligatorio *</span></label>
                            <a-input size="large" v-model="form.usuario"  placeholder="Usuario del Sistema" onfocus="myFunction(this)" style="width: 100%" disabled/>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <span style="color:black; font-size:11px;">La contraseña debe tener una longitud de 8 caracteres y debe contener números, símbolos, letra mayúscula y minúscula.</span><br>
                            <a-button
                                size="large" 
                                type="dashed"
                                block
                                @click="generarPassword()"
                                >
                                <a-icon type="lock" /> Generar Contraseña
                            </a-button>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label>Crea una Contraseña: <span style="font-size:11px; font-weight:700; color:#05729b;"> Obligatorio *</span></label>
                            <a-input-password size="large" v-model="form.password" placeholder="Crear nueva Contraseña"  onfocus="myFunction(this)"/>
                        </div>
                    </div>
                </div>

                <span style="color:black; font-weight: 700; font-size: 15px;"><center>Asignar Roles al Usuario</center></span>

                <br>
                
                <span v-for="(item, index) in roles" :key="index">
                    <p-check 
                        class="p-default p-curve p-pulse p-bigger" 
                        color="primary" 
                        focus 
                        :name="'id'+ item.id" 
                        :value="[{'id': item.id}]" 
                        v-model="form.roles[item.id]"
                        >
                        <span style="color:black;">&nbsp;{{ index + 1 }}. {{ item.name.toUpperCase() }}</span>
                    </p-check>									
                    <br>
                </span>

                <br>

                <a-button
                    v-if="$can('manager.crear.usuario') && tipomodal === 'agregar'" 
                    size="large" 
                    type="primary"
                    block
                    @click.once="registrar()" :key="buttonKeyRegistrar"
                    >
                    REGISTRAR NUEVO USUARIO
                </a-button>

                <a-button
                    v-if="$can('manager.editar.usuario') && tipomodal === 'editar'" 
                    size="large" 
                    type="primary"
                    block
                    @click.once="editar()" :key="buttonKeyActualizar"
                    >
                    ACTUALIZAR USUARIO
                </a-button>
			</div>
					
			<template slot="footer">
				<a-button key="back" @click="modalUsuario = false">
					Cerrar Modal
				</a-button>
			</template>
		</a-modal>

        <!-- MODAL USUARIO TEMPORALES-->
        <a-modal
			v-model="modalLimpiarPapelera"
			title="USUARIOS ELIMINADOS"
			:closable="true"
			:destroyOnClose="true"
			:maskClosable="false"
			:dialog-style="{ top: '5px' }"
			:width="1000"
			:zIndex="1049"
			>

			<div class="row mb-12">
                <span style="color:black; font-weight: 700; font-size: 15px;"><center>REESTABLECER O ELIMINAR PERMANENTEMENTE LOS USUARIOS</center></span>

                <br>

                <template v-if="hasSelected">
                    {{ `Seleccionado ${selectedRowKeys.length} Registros` }}
                </template>
                    
                <a-table
                    v-if="$can('paginar.usuario.temporal')"
                    :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" 
                    :columns="columnsTemporal" 
                    :data-source="usuariosTemporal" 
                    :pagination="paginateTemporal"
                    :loading="loading"
                    :ellipsis="true"
                    :scroll="{ x: 980 }"
                    size="small"
                    rowKey="usuario_id"
                    @change="handlePaginationTemporal"
                    >

                    <template slot="estado" slot-scope="estado">
                        <a-tag color="green" v-if="estado === 'HABILITADO'">
                            HABILITADO
                        </a-tag>
                        <a-tag color="red" v-if="estado === 'DESHABILITADO'">
                            DESHABILITADO
                        </a-tag>
                    </template>

                    <template slot="roles" slot-scope="item">
                        <template v-if="Object.entries(item.roles).length > 0">
                            <span v-for="(value, index) in item.roles" :key="index">
                                <span v-if="value.name === 'administrador'" style="color:#197903; font-size:14px; font-weight:700;">&nbsp;{{ index + 1 }}. {{ value.name.toUpperCase() }} <br></span>
                                <span v-if="value.name != 'administrador'" style="color:#4d2b80; font-size:14px; font-weight:700;">&nbsp;{{ index + 1 }}. {{ value.name.toUpperCase() }}<br></span>
                            </span>
                        </template>

                        <span v-if="Object.entries(item.roles).length === 0" style="color:#8a0900; font-size:14px; font-weight:700;">Sin Rol Asignado<br></span>
                    </template>

                    <template slot="fechaEliminacion" slot-scope="item">
                        {{ utils.formatDate(item.deleted_at) }}
                    </template>
                </a-table>

                <br>

                <a-row>
                    <a-col :span="12">
                        <a-button
                            v-if="$can('reestablecer.usuario.temporal')"
                            size="large" 
                            type="primary"
                            block
                            @click.once="confirmReestablecerUsuario()" :key="buttonKeyReestablecer"
                            :disabled="(selectedRowKeys.length > 0) ? false : true"
                            >
                            <a-icon type="undo" /> 
                            <span v-if="hasSelected">{{ `${selectedRowKeys.length} Re-establecer Registro(s)` }}</span>
                            <span v-else>Re-establecer Registro(s)</span>
                        </a-button>
                    </a-col>
                    <a-col :span="12">
                        <a-button
                            v-if="$can('eliminar.permanente.usuario.temporal')"
                            size="large" 
                            type="danger"
                            block
                            @click.once="confirmEliminarPermanenteUsuario()" :key="buttonKeyEliminarPermanente"
                            :disabled="(selectedRowKeys.length > 0) ? false : true"
                            >
                            <a-icon type="delete" />
                            <span v-if="hasSelected">{{ `${selectedRowKeys.length} Eliminar Permanentemente Registro(s)` }}</span>
                            <span v-else>Eliminar Permanentemente Registro(s)</span>
                        </a-button>
                    </a-col>
                </a-row>
			</div>
					
			<template slot="footer">
				<a-button key="back" @click="modalLimpiarPapelera = false">
					Cerrar Modal
				</a-button>
			</template>
		</a-modal>
    
        <div v-if="spinnerloading == true" class="col-md-12" style="position: fixed; top: 0; right: 0; bottom: 0; left: 0; background: rgba(0, 0, 0, 0.5); z-index: 1055;">
            <div style="margin-top: 250px;">
                <vue-simple-spinner :line-size="10" size="massive" text-fg-color="#f3f3f3" message="Procesando Informacion..."></vue-simple-spinner>
                <br><br>
                <center><button type="button" class="btn btn-warning btn-sm" title="Cancelar" @click="spinnerloading = false"><i class="fa fa-stop"></i> Cerrar Ventana de Espera</button></center>
            </div>
        </div>
	</div>
</template>

<script>
    const columns = [
        { 
            id: 'usuario_id',
            title: 'Id', 
            dataIndex: 'usuario_id',
            key: 'usuario_id',
            width: '7%',
            sortDirections: ['descend', 'ascend', false],
            sorter: true
        },
        { 
            id: 'usuario_id',
            title: 'Nombre', 
            dataIndex: 'nombre',
            key: 'nombre',
            width: '20%',
            sortDirections: ['descend', 'ascend', false],
            sorter: true,
            ellipsis: true
        },
        { 
            id: 'usuario_id',
            title: 'Usuario', 
            dataIndex: 'usuario',
            key: 'usuario',
            width: '15%',
            sortDirections: ['descend', 'ascend', false],
            sorter: true
        },
        { 
            id: 'usuario_id',
            title: 'Email', 
            dataIndex: 'email',
            key: 'email',
            width: '27%',
            sortDirections: ['descend', 'ascend', false],
            sorter: true,
            ellipsis: true
        },
        { 
            id: 'usuario_id',
            title: 'Estado', 
            dataIndex: 'estado',
            key: 'estado',
            scopedSlots: { customRender: 'estado' },
            width: '15%',
            sortDirections: ['descend', 'ascend', false],
            sorter: true
        },
        { 
            id: 'usuario_id',
            title: 'Roles', 
            dataIndex: '',
            key: 'roles',
            scopedSlots: { customRender: 'roles' },
            width: '21%'
        },
        { 
            id: 'usuario_id',
            title: 'Sucursal', 
            dataIndex: '',
            key: 'almacenID',
            scopedSlots: { customRender: 'Sucursal' },
            width: '15%',
            sorter: true
        },
        { 
            id: 'usuario_id',
            title: 'Acciones', 
            dataIndex: '',
            scopedSlots: { customRender: 'action' },
            width: '12%'
        }
    ]

    const columnsTemporal = [
        { 
            id: 'usuario_id',
            title: 'ID', 
            dataIndex: 'usuario_id',
            key: 'usuario_id',
            width: '5%',
            sortDirections: ['descend', 'ascend', false],
            sorter: true
        },
        { 
            id: 'usuario_id',
            title: 'NOMBRE COMPLETO', 
            dataIndex: 'nombre',
            key: 'nombre',
            width: '20%',
            sortDirections: ['descend', 'ascend', false],
            sorter: true
        },
        { 
            id: 'usuario_id',
            title: 'USUARIO', 
            dataIndex: 'usuario',
            key: 'usuario',
            width: '15%',
            sortDirections: ['descend', 'ascend', false],
            sorter: true
        },
        { 
            id: 'usuario_id',
            title: 'ESTADO', 
            dataIndex: 'estado',
            key: 'estado',
            scopedSlots: { customRender: 'estado' },
            width: '15%',
            sortDirections: ['descend', 'ascend', false],
            sorter: true
        },
        { 
            id: 'usuario_id',
            title: 'ROLES', 
            dataIndex: '',
            key: 'roles',
            scopedSlots: { customRender: 'roles' },
            width: '20%'
        },
        { 
            id: 'usuario_id',
            title: 'F.ELIMINACION', 
            dataIndex: '',
            key: 'deleted_at',
            scopedSlots: { customRender: 'fechaEliminacion' },
            width: '20%',
            sortDirections: ['descend', 'ascend', false],
            sorter: true
        }
    ]

    import locale from 'ant-design-vue/es/date-picker/locale/es_ES'
	import moment from 'moment'
	import 'moment/locale/es'
	import { mapGetters, mapActions } from 'vuex'
	import * as utils from '@/utils/utils'
	import router from '@/config/router'

	export default {

		props: {
            config: { required: true },
            usuario: { required: true }
        },

		data () {
			return {
                dateFormat: 'YYYY-MM-DD',
                utils,
				moment,
				locale,
                columns,
                columnsTemporal,
                consulta: {
                    fecha_inicio: '',
                    fecha_final: ''
                },
                form: {
                    usuarioID: '',
                    almacenID: '',
                    nombre: '',
                    direccion: '',
                    telefono: '',
                    email: '',
                    usuario: '',
                    password: '',
                    estado: 'HABILITADO',
                    roles: []
                },
                showModal: false,
                tipomodal: '',
                spinnerloading: false,
                modalUsuario: false,
                modalUsuarioPermiso: false,
                loading: false,
                lista_estado: [
                    { nombre: 'HABILITADO' },
                    { nombre: 'DESHABILITADO' }
                ],
                buttonKeyRegistrar: 10,
                buttonKeyActualizar: 20,
                buttonKeyEliminar: 30,
                buttonKeyLimpiar: 40,
                buttonKeyReestablecer: 50,
                buttonKeyEliminarPermanente: 60,
                paginate: {
                    pageSize: 10,
                    current: 1,
                    total: 0,
                    field: 'usuario_id',
                    order: 'descend'
                },
                paginateTemporal: {
                    pageSize: 10,
                    current: 1,
                    total: 0,
                    field: 'usuario_id',
                    order: 'descend'
                },
                modalLimpiarPapelera: false,
                selectedRowKeys: []
			}
		},

		computed: {
			...mapGetters('usuario', ['usuarios', 'roles', 'usuariosTemporal']),
            ...mapGetters('almacen', ['almacenes']),

            hasSelected () {
                return this.selectedRowKeys.length > 0
            }
		},

		methods: {
			...mapActions('usuario', ['createUsuario', 'actualizarUsuario', 'getUsuarios', 'deleteUsuario', 'getRoles', 'getUsuariosTemporal', 'reestablecer', 'eliminarPermanente']),
            ...mapActions('almacen', ['getAlmacenListar']),

            capturarTecla (event) {
                if (this.tipomodal === 'agregar') {
                    this.form.usuario = this.form.email.split('@')[0]
                }
            },

            onSelectSucursal (value) {
                this.form.almacenID = value.key
            },

            onSelectEstado (value) {
                this.form.estado = value
            },

            generarPassword () {
                const password = utils.generarPasswordForte()
                this.form.password = password
				this.form.confirm_password = password
            },

            limpiarDatos () {
				this.form.nombre = 'Usuario'
				this.form.usuario = 'usuario'
				this.form.email = 'email@example.com'
                this.form.direccion = 'Zona Central'
                this.form.telefono = '77000000'
				this.form.password = ''
				this.form.confirm_password = ''
                this.form.estado = 'HABILITADO'
                this.form.almacenID = 'Seleccionar'
                this.form.roles = []
				// this.$v.form.$reset()
			},

            registrar () {
				this.spinnerloading = true
				this.createUsuario(this.form)
                .then(response => {
                    if (response.status === 201) {
                        this.paginarResultados()
					    utils.openNotificationWithIcon('success', 'Exito', response.data, 'topRight')
                        this.modalUsuario = false
                        this.spinnerloading = false
                    }
				})
                .catch(error => {
					this.loading = false
					utils.openNotificationWithIcon('error', 'Error al enviar la Informacion', utils.showErrors(error.response.data), 'topRight')
				})
                this.buttonKeyRegistrar++
			},

			editar () {
				this.spinnerloading = true
				this.actualizarUsuario(this.form)
                .then(response => {
                    if (response.status === 201) {
                        this.paginarResultados()
					    utils.openNotificationWithIcon('success', 'Exito', response.data, 'topRight')
                        this.modalUsuario = false
                        this.spinnerloading = false
                    }
				}).catch(error => {
					this.loading = false
					utils.openNotificationWithIcon('error', 'Error al enviar la Informacion', utils.showErrors(error.response.data), 'topRight')
				})
                this.buttonKeyActualizar++
			},

			eliminar (id) {
                this.spinnerloading = true
				this.deleteUsuario(id)
                .then(response => {
                    if (response.status === 201) {
                        this.paginarResultados()
					    utils.openNotificationWithIcon('success', 'Exito', response.data, 'topRight')
                        this.spinnerloading = false
                    }
				}).catch(error => {
					this.loading = false
					utils.openNotificationWithIcon('error', 'Error al enviar la Informacion', utils.showErrors(error.response.data), 'topRight')
				})
                this.buttonKeyEliminar++
			},

            obtenerListadoActual (value) {
                // this.listaUsuarios()
            },

            /** TABLA DE IMPORTES */
            handlePagination (paginate, filters, sorter) {
                this.paginate = {
                    pageSize: paginate.pageSize,
                    current: paginate.current,
                    total: paginate.total,
                    field: (sorter.field) ? sorter.field : 'usuario_id',
                    order: (sorter.order) ? sorter.order : 'descend'
                }
                this.paginarResultados()
            },

            changePaginate (data) {
                this.paginate = {
                    pageSize: data.per_page,
                    current: data.current_page,
                    total: data.total,
                    field: 'usuario_id',
                    order: 'descend'
                }
            },

            onSearchUsuario (value) {
                // this.spinnerloading = true
                // const formData = new FormData()
                // const ecode = new EuropioCode()
                // formData.append('almacenID', (this.consulta.almacen_id) ? this.consulta.almacen_id : this.almacenid)
                // formData.append('keyword', ecode.encode_direct(value))
                // formData.append('paginate', ecode.encode_direct(JSON.stringify(this.paginate)))
                // axios.post('/api/libreria/usuario/buscar', formData)
                // .then(response => {
                //     if (response.status === 200) {
                //         this.lista_usuarios = response.data
                //         this.changePaginate(response.data)
                //         this.spinnerloading = false
                //     }
                // })
                // .catch(error => {
				// 	utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data.errors), 'topRight')
                // })	
            },

			openModalUsuario (tipo, data) {
				this.limpiarDatos()
				this.spinnerloading = true
				this.tipomodal = tipo

                if (tipo === 'editar') {
                    const nuevoObj = {}
                    const roles = data.roles.map((item, index) => {
                        return item.id
                    })
                    for (const clave in roles) {
                        const valor = roles[clave]
                        nuevoObj[valor] = true
                    }
					
                    setTimeout(function () {
                        this.form.usuarioID = data.usuario_id
					    this.form.nombre = data.nombre
                        this.form.usuario = data.usuario
                        this.form.email = data.email
                        this.form.direccion = data.direccion
                        this.form.telefono = data.telefono
                        this.form.estado = data.estado
                        this.form.almacenID = data.almacenID.toString()
                        this.form.roles = nuevoObj

                        this.buttonKeyActualizar++
                        this.modalUsuario = true
                        this.spinnerloading = false
                    }.bind(this), 300)
				} else if (tipo === 'agregar') {
                    setTimeout(function () {
                        this.buttonKeyRegistrar++
                        this.modalUsuario = true
                        this.spinnerloading = false
                    }.bind(this), 300)
                }
			},

            paginarResultados () {
                this.getUsuarios(this.paginate)
                .then(response => {
                    this.changePaginate(response.data.meta)
                }).catch(error => {
                    this.loading = false
                    utils.openNotificationWithIcon('error', 'Error al enviar la Informacion', utils.showErrors(error.response.data), 'topRight')
                })
            },

            openModalLimpiarPapelera () {
                this.selectedRowKeys = []
                this.paginarResultadosTemporal()
                this.spinnerloading = true

                setTimeout(function () {
                    this.spinnerloading = false
                    this.modalLimpiarPapelera = true
                    this.buttonKeyLimpiar++
                }.bind(this), 300)
            },

            /** TABLA DE IMPORTES */
            handlePaginationTemporal (paginate, filters, sorter) {
                this.paginateTemporal = {
                    pageSize: paginate.pageSize,
                    current: paginate.current,
                    total: paginate.total,
                    field: (sorter.field) ? sorter.field : 'usuario_id',
                    order: (sorter.order) ? sorter.order : 'descend'
                }
                this.paginarResultadosTemporal()
            },

            changePaginateTemporal (data) {
                this.paginateTemporal = {
                    pageSize: data.per_page,
                    current: data.current_page,
                    total: data.total,
                    field: 'usuario_id',
                    order: 'descend'
                }
            },

            paginarResultadosTemporal () {
                this.spinnerloading = true
                this.getUsuariosTemporal(this.paginateTemporal)
                .then(response => {
                    this.changePaginateTemporal(response.data.meta)
                    this.spinnerloading = false
                }).catch(error => {
                    this.loading = false
                    utils.openNotificationWithIcon('error', 'Error al enviar la Informacion', utils.showErrors(error.response.data), 'topRight')
                })
            },

            onSelectChange (selectedRowKeys) {
                console.log('selectedRowKeys changed: ', selectedRowKeys)
                this.selectedRowKeys = selectedRowKeys
            },

            confirmReestablecerUsuario () {
                const $this = this
                this.$confirm({
                    title: '¿Quieres reestablecer los usuarios eliminados?',
                    content: 'Al hacer clic en el botón Ok, se reestablecera los usuarios seleccionados en el listado de usuarios disponibles en el sistema.',
                    zIndex: 1050,
                    onOk () {
                        $this.reestablecerUsuario()
                        $this.buttonKeyReestablecer++
                    },
                    onCancel () {}
                })
            },

            reestablecerUsuario () {
                this.spinnerloading = true
                this.reestablecer(this.selectedRowKeys)
                .then(response => {
                    if (response.status === 201) {
                        this.paginarResultados()
                        this.paginarResultadosTemporal()
                        utils.openNotificationWithIcon('success', 'Exito', response.data, 'topRight')
                        this.modalLimpiarPapelera = false
                        this.spinnerloading = false
                    }
                })
                .catch(error => {
                    this.loading = false
                    utils.openNotificationWithIcon('error', 'Error al enviar la Informacion', utils.showErrors(error.response.data), 'topRight')
                })
                this.buttonKeyReestablecer++
            },

            confirmEliminarPermanenteUsuario () {
                const $this = this
                this.$confirm({
                    title: '¿Quieres eliminar los usuarios permanentemente?',
                    content: 'Al hacer clic en el botón Ok, se eliminaran los usuarios seleccionados en el listado de usuarios temporales en el sistema.',
                    zIndex: 1050,
                    onOk () {
                        $this.eliminarPermanenteUsuario()
                        $this.buttonKeyEliminarPermanente++
                    },
                    onCancel () {}
                })
            },

            eliminarPermanenteUsuario () {
                this.spinnerloading = true
				this.eliminarPermanente(this.selectedRowKeys)
                .then(response => {
                    if (response.status === 201) {
                        this.paginarResultados()
                        this.paginarResultadosTemporal()
					    utils.openNotificationWithIcon('success', 'Exito', response.data, 'topRight')
                        this.modalLimpiarPapelera = false
                        this.spinnerloading = false
                    }
				})
                .catch(error => {
					this.loading = false
					utils.openNotificationWithIcon('error', 'Error al enviar la Informacion', utils.showErrors(error.response.data), 'topRight')
				})
                this.buttonKeyEliminarPermanente++
            }
		},

        mounted () {
            this.paginarResultados()
            this.getRoles()
            this.getAlmacenListar()
        }

	}
</script>

<style lang="scss">
    @import '~pretty-checkbox/src/pretty-checkbox.scss';

    .pretty {
        position: relative;
        display: grid;
        margin-right: 1em;
        white-space: nowrap;
        line-height: 1;
    }
</style>